.slick-prev {
  left: -6% !important;
  z-index: 1;

}

.slick-next {
  right: -4% !important;
  z-index: 1;

}

.slick-prev,
.slick-next {
  font-size: 15px !important;
}

.slick-prev:before,
.slick-next:before {
  content: '' !important;
}

.slick-slide {
  float: left !important;
  min-height: 1px !important;
  height: 500px !important;
}

/* .slick-slide div {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  border-radius: 10px;
} */




.blogCarouselContentStyle{
  background-color: #fffbe6; 
  height: 100%;
  justify-content: center; 
  align-items: flex-start; 
  padding: 2% 4%; 
  flex-direction: column;
  border-radius: 0 10px 10px 0; 
}
.imgHeightWidth{
  height: 100%;
  border-radius: 10px 0 0 10px;
}

@media  (min-device-width: 600px) and (max-device-width: 950px) {
  .slick-prev {
    left: -9% !important;
    z-index: 1;
  }
  .slick-next {
    right: -5% !important;
    z-index: 1;
  
  }
}

@media only screen and (max-width: 599px) {

  /* Big smartphones [426px -> 600px] */
  /* .slick-slide div {
    height: 100%;
    display: flex ;
    flex-direction: column;
    align-items: flex-start;
    
  } */
  .blogCarouselContentStyle{
    height: 35vh;
    border-radius: 0 0 10px 10px;
  }
  .imgHeightWidth{
    height: 35vh;
    width: 100%;
    border-radius: 0 0 10px 10px;
  }
  .slick-slide {
    /* height: 600px !important; */
    height: 70vh !important;
    display: flex !important;
    align-items: center;

  }
  .slick-prev {
    left: -14% !important;
    z-index: 1;
  
  }
  
  .slick-next {
    right: -9% !important;
    z-index: 1;
  
  }
  .iconHeight{
    width: 2rem;
  }

  .slick-dots{
    bottom: 0;
  }
  
}


/* .custom-prevArrow,
.custom-nextArrow {
  position: relative;
  z-index: 99999;
  top: -10px;
} */
.custom-prevArrow {
  left: 3% !important;
  z-index: 1;
}

.custom-nextArrow {
  right: 3% !important;
  z-index: 1;
}

/* .slick-dots{
  bottom: -55px;
} */

.slick-dots li button:before {
  font-size: 16px;
  line-height: 16px;
  /* margin: 81.4px 113.5px 108px 15.6px; */
  /* padding: 0 144.9px 0px 0; */
  margin: 0;
  padding: 0;
  top: 35px;
  left: 0;
  color: #F27024;
}
.slick-dots li.slick-active button:before {
  color:#F27024 !important;
}

.slick-prev {
  left: -6% !important;
  z-index: 1;

}

.slick-next {
  right: -4% !important;
  z-index: 1;

}

.slick-prev,
.slick-next {
  font-size: 15px !important;
}

.slick-prev:before,
.slick-next:before {
  content: '' !important;
}

.slick-slide {
  float: left !important;
  min-height: 1px !important;
  height: 500px !important;
}

/* .slick-slide div {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  border-radius: 10px;
} */



.homeCarouselContentStyle{
  background-color: #ffbe63; 
  height: 100%;
  justify-content: center; 
  align-items: flex-start; 
  padding: 2% 4%; 
  flex-direction: column;
  border-radius: 10px 0 0 10px; 
}

.talkCarouselContentStyle{
  background-color: #FFFBE6; 
  height: 100%;
  justify-content: center; 
  align-items: flex-start; 
  padding: 2% 4%; 
  flex-direction: column;
  border-radius: 10px 0 0 10px; 
}

.blogCarouselContentStyle{
  background-color: #fffbe6; 
  height: 100%;
  justify-content: center; 
  align-items: flex-start; 
  flex-direction: column;
  border-radius: 10px 0 0 10px; 
}
.watchCarouselContentStyle{
  background-color: #fffbe6; 
  height: 100%;
  justify-content: center; 
  align-items: center; 
  padding: 2% 4%; 
  flex-direction: column;
  border-radius: 10px 0 0 10px; 
}
.imgHeightWidth{
  height: 100%;
  min-height: 100%;
}

@media only screen and (max-width: 599px) {

  /* .slick-slide div {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  } */
  .homeCarouselContentStyle{
    height: 100%;
    border-radius: 10px 10px 0 0;
  }
  .talkCarouselContentStyle{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
  .watchCarouselContentStyle{
    height: 100%;
    border-radius: 10px 10px 0 0;
    flex-direction: row;
  }
  .imgHeightWidth{
    height: 100%;
    min-height: 100%;
  }
  .slick-slide {
    height: 70vh !important;
    display: flex !important;
    align-items: center;
  }
  .slick-prev {
    left: -14% !important;
    z-index: 1;
    display: none;
  }
  
  .slick-next {
    right: -10% !important;
    z-index: 1;
    display: none;
  }
  .iconHeight{
    width: 2rem;
  }
  
}


/* .custom-prevArrow,
.custom-nextArrow {
  position: relative;
  z-index: 99999;
  top: -10px;
} */
.custom-prevArrow {
  left: 3% !important;
  z-index: 1;
}

.custom-nextArrow {
  right: 3% !important;
  z-index: 1;
}

.slick-dots{
  bottom: 0;
}

/* .slick-dots li button:before {
  font-size: 16px;
  line-height: 16px;
  margin: 81.4px 113.5px 108px 15.6px;
  padding: 0 144.9px 0px 0;
  color: #F27024;
}
.slick-dots li.slick-active button:before {
  color:#F27024 !important;
}
.white-border {
  width: 100%;
  height: 1px;
  margin: 0 0 45px;
  background-color: #e6e6e6;
} */